export default {
    section1:{
        title:"Liquidity Solutions",
        desc:"VLINK provides various liquidity solutions, including spot liquidity, derivative liquidity and leveraged ETF liquidity."
    },
    section2:{
        title:"Industry-leading liquidity",
        item1:{
            desc1:"Gain access to the deep liquidity pools of major exchanges",
            desc2:"Geographically flexible, with servers for clients around the world",
            desc3:"Close system monitoring to minimize downtime; we provide in-depth training for clients",
            desc4:"Customizable spreads and markups",
            desc5:"Supports multi-currency exchanges",
            desc6:"Tailored market making solutions",
        },
        desc1:"Gain access to the deep liquidity pools of major exchanges.",
        desc2:"Explosive spot liquidity, leveraged ETF liquidity, derivatives liquidity and other solutions.",
        desc3:"Supports multi-currency exchanges，Customized solutions."
    },
    section3:{
        title:"Liquidity solution",
        desc1:"Gain access to the deep liquidity pools of major exchanges",
        desc2:"Explosive spot liquidity, leveraged ETF liquidity, derivatives liquidity and other solutions",
        desc3:"Supports multi-currency exchanges，Customized solutions",
        futures:{
            desc1:"Decrease market risk",
            desc2:"Stability",
            desc3:'Cost-effective',
            desc4:"Fault-tolerance mechanism",
            desc5:'Flexible deployment',
            desc6:'Comprehensive business management'
        },
    },
    section4:{
        title:"Key Features",
        item1:{
            title:"Remarketer",
            title1:'This unique software allows full customization of order book depth and thresholds to independently price orders, while supporting both sides of the market. Add liquidity to your exchange and increase the depth of your order book, while providing a new revenue stream for your business.',
            desc1:'Reduce market risks',
            desc2:'Real-time optimal pricing and execution',
            desc3:'Automated market making',
            desc4:'Redefine market making orders'
        },
        item2:{
            title:"Liquidity Aggregation",
            title1:'We aggregate liquidity from numerous global exchanges to obtain better depth, lower bid-ask spreads, and better bid-ask prices.',
            desc1:'Liquidity aggregation enables you to become a large liquidity provider for downstream brokers, exchanges, etc.',
            desc2:'Enables customization of price spreads'
        },
    },
    section5:{
        title:"Customized solution",
        item1:{
            title:'Spot Liquidity Solutions',
            title1:'Includes both coin-margined liquidity and fiat-margined liquidity solutions. Coin-margined liquidity refers to providing liquidity for different cryptocurrencies to be exchanged for one another, including USDT, USDC, BUSD, BTC, ETH, etc. and trading pairs such as BTC/USDT, BTC/USDC, BTC/BUSD, and ETH/BTC. Fiat-margined liquidity refers to providing liquidity for different cryptocurrency to be exchanged for fiat currencies such as the US dollar, with trading pairs such as BTC/USD, ETH/USD, etc.',
            desc1:'Best bids and offers',
            desc2:'Re-market liquidity',
            desc3:'Tiered back-end account management',
            desc4:'Comprehensive business monitoring',
            desc5:'Flexible co-location deployment',
            desc6:'Customized spreads and mark-ups',
            desc7:'Comprehensive statistics reports',
            desc8:'Complete order history',
            desc9:'Effective position-managing strategies',
            desc10:'Bridging of decentralization-to-centralization liquidity transition',
        },
        item2:{
            title:'Derivatives Liquidity Solutions',
            title1:'Perpetual derivatives liquidity refers to providing liquidity for USDT-margined perpetual derivatives, such as BTC/USDT, ETH/USDT, etc.',
            desc1:"Best bids and offers",
            desc2:'Re-market liquidity',
            desc3:"Fault tolerance mechanisms under unusual circumstances",
            desc4:"Comprehensive statistics reports",
            desc5:'Tiered back-end account management',
            desc6:'Comprehensive business monitoring',
            desc7:'Comprehensive statistics reports',
            desc8:'Complete order history',
            desc9:'Effective position-managing strategies',
            desc10:'Flexible risk management options by using an order management system'
        },
        item3:{
            title:'Fiat Liquidity Solutions',
            title1:'Fiat liquidity solution refers to providing liquidity based on underlying popular cryptocurrency, such as BTC/USDT, ETH/USDT, etc. The solution of underlying cryptocurrency can be customized, as well as funding fees, leverage rebalancing, and other functions.',
            desc1:'Best bids and offers',
            desc2:'Flexible co-location deployment',
            desc3:'Fault tolerance mechanisms under unusual circumstances',
            desc4:'Comprehensive statistics reports',
            desc5:'Tiered back-end account management',
            desc6:'Risk management system',
            desc7:'Re-market liquidity',
            desc8:'Complete order history',
            desc9:'Rebalancing modules',
            desc10:'Customizable funding fee modules'
        }
    },
    section6:{
        title:'Service advantages',
        item1:{
            title:"30+ Exchanges in Depth",
            desc:'VLink aggregates the depth of more than 30 exchanges worldwide, bringing enough traffic for clients. Enhance the trading volume while avoiding the risk of being arbitrage loss.'
        },
        item2:{
            title:"Ultra-low commission rate",
            desc:'Stepped rates, industry best quotes, ultra-low spreads, and reduced hedging costs.'
        },
        item3:{
            title:"100+ public chains, hundreds of trading pair",
            desc:'Connect to 100+ public chains, open hundreds of trading pairs. Hot and cold wallets and a strong risk control system ensure the safety of your funds.'
        }
    },
    section7:{
        title:'Application Scenarios',
        item1:{
            title:"Applicable Teams",
            desc1:'Cryptocurrency exchanges',
            desc2:'Cryptocurrency brokers',
            desc3:'Institutional investors'
        },
        item2:{
            title:'Applicable scenarios',
            desc1:'Mainstream trading pair liquidity',
            desc2:'Future trading liquidity',
            desc3:'Spot trading liquidity',
        }
    }
}