
export default {
    section1:{
        title1:"數字資產交易所",
        titleH:'一站式',
        title2:"解決方案",
        desc:"維鏈科技旗下主要有區塊鏈數字貨幣交易所、數字資產交易系統、WAAS系統、錢包、流動性、礦池等成熟的產品線，幫助合作夥伴在行業的快速發展中實現商業價值。"
    },
    section2:{
        title:"產品介紹",
        subTitle:'通過我們的專業服務獲得解決方案',
        item1:{
            label:"數字資產交易所",
            title:"數字資產交換解決方案",
            desc1:"服務涵蓋了市場上交易所的覈心交易模塊，例如現貨交易、合約、杠杆、ETF、OTC",
            desc2: "超過10種運營工具，獲得及轉化用戶",
            desc3: "提供業界領先的流動性服務，超過300個交易對"
        },
        item2:{
            label:"交易所錢包管理",
            title:"我們的產品特點",
            desc1:"全Token支持",
            desc2: "多Token多地址統一管理",
            desc3: "提現初審+覆核雙模式",
            desc4: "多地址多資產一鍵歸集",
        },
        item3:{
            label:"流動性服務",
            title:"行業領先的流動性",
            desc1:"主流幣流動性服務，提供市值管理服務解决主流資產交易流動性、交易深度問題",
            desc2:"包括現貨流動性、杠杆ETF流動性、衍生工具流動性等解決方案",
            desc3:"支持多幣種交易、定制的做市解決方案"
        },
        item4:{
            label:"增值服務",
            title:"增值服務",
            desc1:"定制化打造品牌，全網曝光，合作形式曝光，定制化曝光",
            desc2:"定制化孵化方案，打造專業品牌影響力",
            desc3:"豐富的開發經驗，提供專業科技運維、多維度風險檢測"
        },
        item5:{
            label:'運營及維護',
            title:'運營及維護',
            desc1:'整體管理系統，為客戶提供自動即時系統監察與營運及維護服務',
            desc2:'全面的數據存儲、數據分析及數據治理解決方案，確保營運安全',
            desc3:'團隊在全球交易所營運及維護管理方面擁有豐富的經驗',
            desc4:'穩健的監管系統和預警系統，透過設定適當的閾值及整合多個預警渠道，及時監測和處理問題'
        }
    },
    section3:{
        title1:"我們的",
        title2:'成就',
        subTitle:'安全可靠高效專業的團隊',
        text:"產品服務案例",
        btnText:"獲取演示",
        label1:"公司成立時間",
        label2:"頂級技術開發人員",
        label3:"全球用戶",
        label4:"國家和地區"
    },
    section4:{
        title1:"我們的",
        title2:"團隊",
        desc:"維鏈科技拥有超過200人的區塊鏈行業技術研發和運營人才，具有豐富的傳統金融、互聯網技術開發和區塊鏈等服務經驗。",
        label1:'2020年开始创立维链科技技术服务公司，具备超硬核的技术实力和超强的团队协作管理经验',
        label2:'拥有丰富行业知识、具备区块链交易所开发经验及行业资源，擅长交易所技术架构的制定',
        label3:'David担任维链科技的副总裁，负责品牌战略和营销运营。',
        label4:'渣打银行执行总裁、首席财务官、高盛集团前国际顾问。'
    },
    section5:{
        title1:"團隊",
        title2:"基因",
        desc1:"維鏈科技擁有超過200人的團隊，核心成員來自全球IT、金融、軟體工程等行業，曾就職於火幣、SOLANA、a16z、Binance、Microsoft、穀歌、騰訊、百度、亞馬遜、火幣、渣打銀行等企業，具有豐富的傳統金融、證券、互聯網、區塊鏈等服務經驗。 技術研發人員占比超過70%，其中覈心研發人員均擁有10+年研發經驗。",
        desc2:"技術研發人員占比超過70%，其中覈心研發人員均擁有10+年研發經驗。"
    },
    section6:{
        title:'合作夥伴',
        subTitle:'我們與生態系統的夥伴緊密合作，推動行業的發展。',
        desc1:'領先的全球匾瑰安全品牌，提供綜合區塊鏈安全產品及服務',
        desc2:'提供所有即服務，構建智能世界的雲基礎',
        desc3:'Web3 安全公司，致力於將最領先的研究轉化為自動化安全掃描和DevSecOps 功能',
        desc4:'云計算和人工智慧的全球領導者，提供可靠及安全的云計算及資料處理服務',
        desc5:'一個集身份驗證、欺詐預防及KYC/KYB和反洗合規於一身的平臺',
        desc6:'業界領先的 Web3 網絡安全解決方案供應商 ',
    }
} 