<template>
  <div id="app" class="font-regular">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
      desingIsShow :false,
    }
  },
  mounted(){
  },
  methods:{
    showDesign(){
      this.desingIsShow = !this.desingIsShow
    }
  }
}
</script>

<style lang='scss'>

#app {
  background: $color_bg;
  color:$color_text_main;
  font-size: 16px;
}
html,body{
  height: 100%;
  width: 100%;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,li{
  list-style: none;
}

</style>
