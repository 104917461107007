export default {
    header: {
      menu1: "產品",
      menu2: "關於我們",
      menu3: "英語",
      menu4: "獲取演示",
      subMenu1: {
        text: "數字資產交易",
        desc: "數字資產交易所一站式解決方案",
      },
      subMenu2: {
        text: "錢包管理",
        desc: "交易所錢包管理系統",
      },
      subMenu3: {
        text: "流動性",
        desc: "VLINK提供多種流動性解決方案，提供現貨流動性、衍生品流動性和杠杆ETF流動性",
      },
      subMenu4: {
        text: "增值服務",
        desc: "定制品牌，合作曝光，定制曝光",
      },
    },
    footer:{
        text1:"瞭解更多資訊並獲取演示",
        text2:"體驗demo",
        desc:'我們還將與您合作，根據您的業務需求設計定制報價',
        btnText:"聯繫我們",
        navMenu:{
            menu1:"產品",
            menu2:"關於我們",
            menu3:"聯繫我們"
        },
        navLink:{
          text1:"數字資產交易",
          text2:"錢包管理",
          text3:"流動性",
          text4:"增值服務",
          text5:"關於我們",
          text6:"聯繫我們",
          text7:"獲取演示"
        },
        label1:"有問題聯繫？",
    },
    tips:{
      text1:"請填寫您的電子郵件",
      text2:'請填寫您的姓名',
      text3: '提交成功'
    },
    fixIcon:{
      text1:"申請演示",
      text2:"線上客服"
    },
    btnText:{
      text1:"體驗demo",
      text2:"瞭解詳情",
      text3:"瞭解更多",
      text4:'安全機构',
      text5:'合規機构',
      text6:"媒體機构"
    },
    stepLabel:{
      text1:"第一步",
      text2:'第二步',
      text3:'第三步',
      text4:'步驟1',
      text5:'步驟2',
      text6:'步驟3'
    }
  };
  