export default{
    section1:{
        title:"關於我們",
        desc:"全球區塊鏈金融資訊技術服務提供者。成立於2020年，總部位於迪拜。 並在泰國、新加坡、韓國、日本等地擁有專業的科技和運營團隊。全球领先的智能化区块链金融信息技术服务商，成立于 2020 年，总部位于迪拜，并在泰国、新加坡、韩国、日本等均设有专业技术和运营团队。",
        subTitle:"我們的服務宗旨",
        item1:{
            title:"安全",
            desc1:"全球頂級科技團隊研",
            desc2:"發專業科技運維體系，確保硬體質量",
            desc3:"成熟的金融風控體系，符合加密貨幣安全標準（CCSS）",
            desc4:"完善的資金風控體系，多重安全機制確保資產安全"
        },
        item2:{
            title:"可靠",
            desc1:"區塊鏈數據同步更新，主動推送區塊",
            desc2:"獨立服務器私有部署，支持高並發交易訪問",
            desc3:"超強防DDOS攻擊系統時刻為平台保駕護航",
            desc4:"7*24小時專業運維，隨時隨地聯繫我們"
            
        },
        item3:{
            title:"專業",
            desc1:"開放API介面，快速實現數字資產對接",
            desc2:"多錢包多幣種多地址統一管理",
            desc3:"數字資產批量處理，高效審核訂單",
        }
    },
    section2:{
        title:"我們的成就",
        label1:"行業夥伴深度綁定",
        label2:"產品服務案例",
        label3:"頂級技術開發人員",
        label4:"全球用戶",
        label5:"國家及地區",
        label6:"技術研發經驗"
    },
    section3:{
        title:'閱讀维链最新动态'
    },
    section5:{
        title:'客戶評價',
        text1:'在我們陞級以及數據更新中提供了專業的指導與技術支援，在項目過程中展現了專業嫺熟的科技，積極熱情幫助解決項目中遇到的問題，保證了項目順利的推進完成。 對此表示衷心的感謝。',
        text2:'幫助我們安全高效地進行系統部署實施，最佳實踐指導和日常科技問題解答。 用戶和項目的部署實施都很成功，並且沒有發現任何問題。 他們能在任何時間用專業的知識和積極的態度去回答並解决我們的問題。',
    },
    section4:{
        title:"如果您有任何項目或需要幫助。 聯繫我們",
        form:{
            title:"向我們發送消息",
            label1:"姓名",
            label2:"聯繫電話",
            label3:"電子郵件",
            label4:"留言",
            btnText:"提交",
            holder1:"輸入您的名字",
            holder2:"輸入您的手機號",
            holder3:"輸入您的電子郵件",
            holder4:"請給我們留言"
        },
        label1:"聯繫我們:"
    },
    
}