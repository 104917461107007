export default {
    section1: {
        title: "Additional Services",
        desc: "We have a professional brand service team, aiming to help your brands with digital asset exchange marketing services. We are marketing and promotion experts in the blockchain industry."
    },
    section2: {
        title: "What additional services do we have",
        desc: "We provide full-stack services for brand promotion, advertising, technical operations and others. Get a good promotion at a reasonable price.",
        item1: {
            title: "Brand promotion",
            desc1: "Customized branding, cooperation exposure, customized exposure",
            desc2:'Through our comprehensive blockchain PR, marketing and advisory services, you’ll gain one-stop access to blockchain-specific branding strategies.',
            title1:'How it works',
            desc3:'We also work closely with blockchain investors and PR company, special relationships we have nurtured over years to help clients like you.',
            desc4:'VLINK Tech collaborates with some of the largest and well-attended blockchain events all over the world, and through these partnerships we are able to secure premier speaking opportunities at these events and enable our clients to have exposure to global and first-class networking opportunities.',
            step1:'We’ll deliver a consistent number of written media placements per month and You’ll receive introductions and interviews with the most influential influencers and KOLs in the blockchain industry.',
            step2:'Our team will craft your messaging and help you think like a journalist so it easy to digest and you can learn the media landscape.',
            step3:'You’ll have access to top tier national, business and crypto media via VLINK Tech extensive network of contacts and receive speaking opportunities at leading industry events with a full schedule of meetings scheduled for you before you go.'
        },
        item2: {
            title: "Brand Incubation",
            desc1: "Customized incubation program to create professional brand influence",
            desc2:'We offer a full-scale branding and incubation service to suit whatever project you are launching.From creative conceptualization, to brilliant briefs and showstopping visual identities; we understand the importance of presenting a brand that matches your values and principles as a project.',
            desc3:'Our team will arrange a call to delve deep into your mind and extract your ideas and inspiration for your branding, in whatever way is easiest for you.',
            desc4:'We then create 2 branding concepts, presenting them directly to you for feedback and suggestions.',
            desc5:'Our team produce the final concept with all suitable elements ready for small amendments or sign off. You leave with a full-scale visual identity, and we’re proud to have been part of it.'
        },
    },
    section3:{
        title:'Technical operation and maintenance',
        desc1:"Provide professional technical operation 、maintenance and  multi-dimensional risk detection.",
        desc2:'We have a long history of risk management in both the traditional and crypto markets. Our risk control system ensures 24/7 real-time monitoring and the ability to respond to situations in a timely and effective manner. Our smart contract security team comprises some of the most talented people in the industry, and has prevented countless flash-loan and re-entrancy attacks from large decentralized finance (DeFi) protocols.',
        title1:'We have a rigid security culture',
        desc3:'We have a responsive and effective 24/7 Information Security Team, aiming to reduce the risk of significant security incidents and data breaches. Furthermore, we have implemented a zero trust security approach and privileged access management protocol to guard against the misuse of insider access.',
        decs4:'MPC-based infrastructure to ensure asset security',
        desc5:'End-to-end proprietary HSM',
        desc6:'NCC-audited',
        desc7:'Real-time operational risk management system',
        desc8:'Robust information security monitoring',
        desc9:'“Zero-trust” security approach'
    },
    section4:{
        title:'Full-Stack Services',
        desc:'We provide full-stack solutions for the crypto industry, Gamefi、DEFI、NFT、DEX and wallet development. We provide a complete solutions suite covering infrastructure development and ecosystem support for forward-looking businesses exploring leverage blockchain technology, lowering barriers to entry and increasing cost-efficiency.',
        item1:{
            title:'Self-developed and controllable',
            desc:'Self-developed underlying technology controllable. Provide digital asset management function, users can freely customize and build business scenarios.'
        },
        item2:{
            title:'Security and Trust',
            desc:'Security environment ensures stable and reliable operation of blockchain and technical services and reliable flow of digital assets.'
        },
        item3:{
            title:'Ultra High Performance',
            desc:'Supports concurrent casting of digital assets at 10,000,000 levels per second and the circulation of digital assets at 10 billion levels.'
        },
        item4:{
            title:'Easy to use',
            desc:'The development of digital content trading platform can be completed quickly with only SDK connected to realize the whole cycle of time management of digital content.'
        }
    },
    section5: {
        title: "Our Ecology",
        item1: {
            title: " Expand the market",
            desc: "Help with the rapid promotion of the brand, drive the development of the platform and expand the business scope."
        },
        item2: {
            title: "Improve the brand image",
            desc: "Spread and establish the brand, quickly accepted by users."
        },
        item3: {
            title: "Wide coverage",
            desc: "Wide resources, cost-effective promotion costs, wide network information coverage."
        }
    }
}