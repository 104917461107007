import home from './home.js'
import common from './common.js'
import transcation from './transcation.js'
import wallet from './wallet.js'
import liquidity from './liquidity.js'
import additionalService from './additionalService.js'
import aboutus from './aboutus.js'
import tools from './tools.js'

export default {
    home,
    common,
    transcation,
    wallet,
    liquidity,
    additionalService,
    aboutus,
    tools
}