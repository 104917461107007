export default {
  data() {},
  methods: {
    contractUs() {
      if (this.$route.name !== "aboutUs") {
        this.$router.push({
          path: "/aboutUs",
          query: {
            cssSelector: ".sendmessage",
          },
        });
      } else {
        const ele = document.querySelector(".sendmessage");
        ele.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
