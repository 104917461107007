export default{
    section1:{
        title:'專業的營運工具',
        desc:"提供保護區塊鏈系統的硬件、軟件及資料避免意外或惡意損害、變更及披露，籍以確保系統的持續、可靠及穩定運作。"
    },
    section2:{
        title:"主要亮點",
        desc:'提供保護區塊鏈系統的硬件、軟件及資料避免意外或惡意損害、變更及披露，籍以確保系統的持續、可靠及穩定運作。',
        desc1:'超過 10 種營運工具，幫助獲得及轉化用戶',
        desc2:'营销與營運工具的組合及特有的類型和明確的目標，有利於輕鬆採用',
        desc3:'营销工具用於新用戶的獲取與留存',
        desc4:'營運工具用於調動現有用戶在，提高用戶互動與留存'
    },
    section3:{
        item1:{
            title:"逆向交易",
            desc:'逆向交易滿足商戶積極持倉的需求。商戶只需註冊跟單交易帳戶並存入資金。經設定，用戶在此商戶下進行的每筆交易時會執行逆向交易。',
            desc1:"毋須單獨造市即可實現積極持倉",
            desc2:"靈活調整交易倉位的比例",
            desc3:"持有部分交易倉位，上流時毋須準備對沖資金",
            desc4:"客戶可配置高贏率用戶黑名單，黑名單用戶在交易時將無法開倉",
        },
        item2:{
            title:"跟單交易 - 期貨",
            desc:'跟單交易令用戶可自動跟進精選 KOL， 其特色包括利用既定代幣及清算方法進行交易。',
            desc1:"用戶可觀察 KOL 的市場預測和交易策略",
            desc2:"提高操作體驗，同時賺取收益",
            desc3:"保留靈活調整交易倉位的權利，隨時停止交易和清算",
        },
        item3:{
            title:"理財",
            desc:'靈活儲蓄可隨時存入與提取，而定期儲蓄提供高收益。此等功能使交易所可向用戶提供代幣獎勵，定制區域的營運，譬如用戶留存、專案營運及資產託管分析。',
            desc1:"定期（鎖定）儲蓄：儲蓄資金被鎖定，無法交易或提取。在獲得投入本金前，用戶必須等待專案結束和系統發放本金。",
            desc2:"靈活（持有）儲蓄：在活動期間，用戶將能夠交易和提取資產。只有在首日有快照並且活動期內的每個快照有資產結餘時，才會每日支付利息。",
        },
        item4:{
            title:"網格交易",
            desc:'網格交易是一種讓用戶通過計劃在既定價格範圍內自動逢低買入、逢高賣出的策略。在數碼資產波動的市況下，網格化交易可很大程度上避免人為因素登入不當交易決定。網格交易機器人將協助用戶嚴格執行由用戶設定的逢低買入、逢高賣出交易策略。',
            desc1:"在存入單一資產（USDT）啟用網格後，系統將自動以高於期初現價買入既定數量的網格，並在價格上升後賣出。該部分利潤將高於網格套利利潤。",
            desc2:"當價格出現波動時，每個網格套利就是網格利潤",
        }
    },
    section4:{
        desc1:'我们提供整體管理系統，為客戶提供自動即時系統監察與營運及維護服務',
        desc2:'全面的資料存儲、資料分析及資料管治解決方案，確保資產系統的安全運作',
        desc3:'團隊深諳交易所的營運及維護管理'
    },
   
    section5:{
        title:"競爭優勢",
        item1:{
            title:'團隊專業知識',
            desc1:'全面的團隊，包括系統營運及維護工程師團隊、資料庫工程師團隊、安全工程師團隊、安全工程師團隊、營運及維護開發工程師團隊、網絡工程師團隊'
        },
        item2:{
            title:'監察系統',
            desc1:'穩健的監察系統，涵蓋了雲監察、安全監察、系統基礎設施監察、服務可行性監察、資料處理能力監察、網絡監察、日誌監察'
        },
        item3:{
            title:'警報系統',
            desc1:'警報系統以監察系統為基礎，設定了相應門檻和整合了多個警報渠道，從而及時檢測和處理，務求保證平順的營運'
        },
        item4:{
            title:'安全全護',
            desc1:'綜合和多維度的網絡安全全護策略，透過運用安全規則，界定存取權限，多重驗證，包括 VPN、公鑰及 OTP/SMS 登入，確保安全；全面記錄的營運日誌，確保營運及維護標準化'
        },
        item5:{
            title:'資料安全',
            desc1:'資料的即時、多域熱備份及常規冷備份，實現資料零遺失',
            desc2:'資料管治，環球資料災難恢復系統、資料庫日常監察',
            desc3:'多區域即時資料同步，防範資料遺失'
        },
        item6:{
            title:'營運及維護管理系統',
            desc1:'基於交易所業務開發，整合資產管理、監控管理、CI、CD等業務模塊。'
        },
        item7:{
            title:'資產管理',
            desc1:'詳盡記錄完整的資產生命週期',
            desc2:'全面的基礎設施加強',
            desc3:'定期更新基礎設施組件',
            desc4:'定期安全監察和政策更新'
        },
        item8:{
            title:'豐富的經驗',
            desc1:'部署逾 300 個私人交易所，並且維持超過 5,000 個日均活躍用戶',
            desc2:'深諳交易所全模塊及業務資料流',
            desc3:'捷達環球各大 CDN 加速器'
        },
    },
    section6:{
        title:'流動性解決方案',
        title1:'透過我們的解決方案獲得豐裕的流動性池',
        desc1:'300 個 SaaS 交易所的流動資金池',
        desc2:'強大的技術專業知識與頂級安全',
        desc3:'多策略、多交易類型',
        desc4:'支援超過 300 個交易對',
        desc5:'流動性深度可比頂流交易所',
        desc6:'透過再營銷服務，加強客戶訂單的深度，同時創造新的收入流',
        desc7:'伺服器寄存部署，讓環球客戶可靈活選擇地理位置',
        desc8:'24/7 全天候客戶支援，密切監控，減少系統故障，並為客戶提供深入的培訓'
    }
}