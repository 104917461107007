export default {
  header: {
    menu1: "Products",
    menu2: "About us",
    menu4: "Get a demo",
    subMenu1: {
      text: "Exchange Solutions",
      desc: "Full Stack Digital Asset Exchange Technology Service.",
    },
    subMenu2: {
      text: "Wallet Management",
      desc: "Exchange Wallet Management System.",
    },
    subMenu3: {
      text: "Liquidity",
      desc: "VLINK offers a variety of liquidity solutions, providing spot liquidity, derivatives liquidity and leveraged ETF liquidity.",
    },
    subMenu4: {
      text: "Additional Services",
      desc: "Customized branding, cooperation exposure, customized exposure.",
    },
  },
  footer:{
      text1:"Contact Us Get More",
      text2: "Experience Demo",
      btnText:"Contact Us",
      desc:"We will also work with you to design customized quotations according to your business needs",
      navMenu:{
          menu1:"Products",
          menu2:"About Us",
          menu3:"Contact Us"
      },
      navLink:{
        text1:"Digital Exchange",
        text2:"Wallet Management",
        text3:"Liquidity",
        text4:"Additional Services",
        text5:"About Us",
        text6:"Contact Us",
        text7:"Get a Demo"
      },
      label1:"Have a question to contact?",
  },
  tips:{
    text1:"Please enter your email",
    text2:'Please enter your name',
    text3: 'success'
  },
  fixIcon:{
    text1:"request a demo",
    text2:"online service"
  },
  btnText:{
    text1:"Get a Demo",
    text2:"Learn more",
    text3:"Learn more",
    text4:'Security',
    text5:'Compliance',
    text6:"Media"
  },
  stepLabel:{
    text1:"Step One",
    text2:'Step Two',
    text3:'Step Three',
    text4:'Step One',
    text5:'Step Two',
    text6:'Step Three'
  }
};
