export default {
    section1: {
        title: "增值服務",
        desc: "我們擁有專業的品牌服務團隊，旨在幫助用戶品牌開展全球數字資產交易所品牌行銷服務，擁有多年互聯網推廣經驗，是區塊鏈行業的行銷推廣專家。"
    },
    section2: {
        title: "我們有哪些增值服務",
        desc: "我們提供品牌推廣、廣告投放、科技優化等數字資產交易所一站式服務，全站媒體流量採購和推廣平臺，讓您用比同行更低的價格採購到更高質量的流量。",
        item1: {
            title: "品牌宣傳",
            desc1: "定制化打造品牌，全網曝光，合作形式曝光，定制化曝光",
            desc2:'通過我們全面的區塊鏈公關、行銷和諮詢服務，您將獲得一站式區塊鏈的品牌戰略服務。',
            title1:'品牌服務流程',
            desc3:'我們與區塊鏈投資者和媒體密切合作，這是我們多年來培養的特殊關係，旨在幫助像您這樣的客戶。',
            desc4:'維鏈科技與世界各地一些規模最大、參與人數最多的區塊鏈活動合作，通過這些合作夥伴關係，我們能够在這些活動中獲得演講機會，並使我們的客戶能够接觸到全球一流的交流機會。',
            step1:'我們每月將投放數量穩定的媒體宣發，您將受到區塊鏈行業最具影響力的意見領袖與KOL的介紹及訪談。',
            step2:'我們的團隊將精心打磨您的項目資料，並幫助您像記者一樣思考，使內容易於理解傳播，您可以借此瞭解文宣工作的思考管道。',
            step3:'你將通過維鏈科技廣泛的人脈網絡接觸到官方、商界及區塊鏈領域的頂級媒體，並獲得在頂級行業峰會中的演講機會，我們在您出發前將為您安排完整的會議日程。'
        },
        item2: {
            title: "品牌孵化",
            desc1: "定制化孵化方案，打造專業品牌影響力",
            desc2:'我们提供全面的交易所品牌孵化服务，以启动您的项目。从品牌创意到品牌视觉；我们将根据您的价值观和原则作为一个项目品牌展示的概念。',
            desc3:'我們的團隊將安排電話深入瞭解您的想法，並以對您來說最簡單的方式為您的品牌選取想法和靈感。',
            desc4:'我們將會創建2個品牌概念，直接向您展示它們以徵求迴響和建議。',
            desc5:'我们的团队会生成包含所有合适元素的最终概念，以供小幅修改或签署。你带着一个完整的视觉形象离开，我们很自豪能参与其中。'
        },
    },
    section3:{
        title:'科技運維',
        desc1:"豐富的開發經驗，提供專業科技運維、多維度風險檢測",
        desc2:'我們在傳統市場和加密貨幣市場都有悠久的風險管理歷史。 我們的風險控制系統確保24/7即時監控和及時有效地應對情况的能力。 我們的智慧合約安全團隊由業內一些最有才華的科技人才組成，已經封锁了來自大型去中心化金融（DeFi）協定的無數閃貸和重入攻擊。',
        title1:'我們有嚴格的安全文化',
        desc3:'我們擁有一支響應迅速且高效的24/7資訊安全團隊，旨在降低重大安全事件和數據洩露的風險。 此外，我們還實施了零信任安全方法和特權訪問管理協定，以防止濫用內部存取權限。',
        desc4:'基於MPC的基礎設施，保障資產安全',
        desc5:'端到端專有HSM',
        desc6:'NCC审核',
        desc7:'實时操作風險管理系統',
        desc8:'强大的資訊安全監控',
        desc9:'“零信任”安全方法'
    },
    section4:{
        title:'全棧定制化服務',
        desc:'我們提供完整的加密行業的全棧式解決方案，鏈遊、DEFI、NFT、DEX、錢包等技術開發。 涵蓋了針對前瞻性業務的基礎設施開發及生態系統支援，探尋區塊鏈科技的利用管道，降低准入門檻及降本增效。',
        item1:{
            title:'自研可控',
            desc:'自研底層科技，自主可控。 提供通用數位資產管理能力，用戶可自由定制構建業務場景。'
        },
        item2:{
            title:'安全可信',
            desc:'金融級安全環境，保障區塊鏈、技術服務穩定可靠運行、數位資產可靠流轉。'
        },
        item3:{
            title:'超高效能',
            desc:'支持每秒萬級數位資產併發鑄造，支持百億級數位資產發行流轉。'
        },
        item4:{
            title:'簡單易用',
            desc:'僅需SDK對接即可快速完成數位內容交易平臺開發，實現數位內容全生命週期管理。'
        }
    },
    section5: {
        title: "我們的應用生態",
        item1: {
            title: "市場範圍擴大",
            desc: "幫助您的品牌在網絡的快速推廣，帶動平臺的發展，擴大業務範圍。"
        },
        item2: {
            title: "品牌形象的提升",
            desc: "快速傳播品牌宣傳，樹立品牌自身形象，被更多用戶接受。"
        },
        item3: {
            title: "覆蓋面廣",
            desc: "網絡平臺資源眾多，宣傳成本性價比高，網絡資訊覆蓋面廣，傳播迅速。"
        }
    }
}