export default{
    section1:{
        title1:"Exchange",
        titleH:"Wallet",
        title2:"Management System",
        desc:"Our system provides stable, easy and fast blockchain access for corporate and individual customers through blockchain network data processing."
    },
    section2:{
        title:"Our Product Ecology",
        item1:{
            title:"VLINK Businesses version",
            desc:"Crypto asset decentralized management solution for bussinesses."
        },
        item2:{
            title:"VLINK Multi-Sign Wallet",
            desc:"Collaborative management system to help companies manage high funding."
        }
    },
    sectionXix:{
        title:"Exchange wallet management service content",
    },
    sectionJ:{
        title:"Technical framework"
    },
    section3:{
        title:"Our product characteristics",
        item1:{
            title:"Function",
            desc1:"All types of tokens are supported",
            desc2:"Unified management of Token and address",
            desc3:"Double review when withdrawing funds",
            desc4:"Automatic release of funds",
            desc5:"Efficient aggregation system",
            desc6:"Permission management system",
            desc7:"Transactions and alerts system"
        },
        item2:{
            title:"Protection",
            desc1:"API interface information access verification, HTTPS transmission to prevent information leakage.",
            desc2:"Address & private key security management、 Offline management and secondary encryption.",
            desc3:"Multiple managed wallet addresses Separate deposit and withdraw.",
            desc4:"Each address is bound to one device Facility Safety Management.",
            desc5:"Separate hot and cold wallets Manage high funding with a cold wallet."
        },
        item3:{
            title:"Safeguard",
            desc1:"Efficient permission management system",
            desc2:"Efficient record management system",
            desc3:"High funding management system",
            desc4:"Facility management system"
        }
    },
    section4:{
        title:"Access to VLINK wallet management system fastly",
        desc:"Provide API interface, such as deposit, withdraw, address generation, etc., and support all types of digital assets. Public & private keys management system and hacker prevention."
    },
    section5:{
        title:"Risk control system",
        listItem:{
            Sctitle:{
                title:"Internal control of personnel",
                desc:"Perfect internal system specification to prevent internal personnel from causing asset loss"
            },
            item1:{
                title:'Internal operation system',
                desc:'R & D code needs to be audited twice before applying for online deployment, operations and maintenance staff permission management, and the security team regularly audits whether the server sensitive information of online permission meets the deployment specification.'
            },
            item2:{
                title:'Internal risk control awareness',
                desc:'The security team regularly organizes members to review system security, legal education and security awareness education.'
            },
            item3:{
                title:'Different permission management',
                desc:'Operations and maintenance personnel permissions expire periodically, different projects, modules for one-to-one authorization. Management background permissions are also subdivided into read and write, edit granularity.'
            }
        },
        listItem2:{
            Sctitle:{
                title:"business security",
                desc:"Flexible configuration of currency withdrawal limits, approval members and other information"
            },
            item1:{
                title:'Withdrawal policy',
                desc:'Flexible configuration of currency withdrawal limits, approval members and other information'
            },
            item2:{
                title:'Security Authentication',
                desc:'KYC authentication information, Google Authenticator YUBI hardware authentication'
            },
            item3:{
                title:'Verification system',
                desc:'Automatic verification system, real-time monitoring of account funds, to ensure that each fund is transparent'
            },
            item4:{
                title:'Risk Control System',
                desc:'AML, charging traceability, foreign operation authentication, hot and cold wallet'
            },
            item5:{
                title:'Encryption machine',
                desc:'Core data storage and interaction are encrypted by the encryption machine for multi-layer encryption'
            },
            item6:{
                title:'Co-managed wallet',
                desc:'Support more than one person to manage the funds, and more than one person to approve the withdrawal of funds'
            },
            item7:{
                title:'Data Masking',
                desc:'Important data encryption display'
            },
            item8:{
                title:'Audit system',
                desc:'Business system behavior for full-process monitoring to ensure that each step of the operation is complete and effective'
            },
            item9:{
                title:'Warning system',
                desc:'According to the alarm evaluation system, abnormal behavior and data are warned in advance to prevent problems before they occur. System health self-test, interface monitoring, withdraw monitoring'
            },
            item10:{
                title:'Permission System',
                desc:'The decentralized distribution of various permissions for employees and systems improves security while meeting efficiency'
            },
        },
        listItem3:{
            Sctitle:{
                title:"System O&M security",
                desc:"Robust server deployment framework minimizes the impact of external factors on the system"
            },
            item1:{
                title:'Co-location dual-active architecture',
                desc:'Co-location of services, co-location of data disaster recovery, cross-room level disaster recovery without data loss in the same city'
            },
            item2:{
                title:'Business Subnet Isolation',
                desc:'Different subnets for different services to physically isolate the system from transgression security and solve the problem of personnel hierarchy simultaneously'
            },
        },
    }
}