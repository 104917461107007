export default {
    section1:{
        title1:'Full Stack',
        titleH:'Digital Asset Exchange',
        title2:"Solutions ",
        desc:"VLINK Group's mature product lines, including blockchain digital currency exchange, digital asset trading system, WAAS system, wallet, liquidity and mining pool, help partners realize business value in the rapidly developing market of the cryptocurrency industry."
    },
    section2:{
        title1:"Our ",
        title2:"Products",
        subTitle:'Get solutions with our professional services',
        item1:{
            label:"Exchange Solutions",
            title:"Digital Asset Exchange Solutions",
            desc1:"Including: Spot trading, Futures trading、 ETF、NFT、OTC.",
            desc2: "Over 10 operating tools to get users.",
            desc3: "Provide the world's leading liquidity service, supporting over 300 symbol."
        },
        item2:{
            label:"Wallet Management",
            title:"Our product characteristics",
            desc1:"All types of tokens are supported.",
            desc2: "Unified management of Token and address.",
            desc3: "Double review when withdrawing funds.",
            desc4: "Efficient aggregation system.",
        },
        item3:{
            label:"Liquidity",
            title:"Industry Leading Liquidity",
            desc1:"Gain access to the deep liquidity pools of major exchanges.",
            desc2:"Explosive spot liquidity, leveraged ETF liquidity, derivatives liquidity and other solutions.",
            desc3:"Supports multi-currency exchanges，Customized solutions."
        },
        item4:{
            label:"Additional Services",
            title:"Additional Services",
            desc1:"Customized branding, cooperation exposure, customized exposure.",
            desc2:"Customized incubation program to create professional brand influence.",
            desc3:"Provide professional technical operation 、maintenance and  multi-dimensional risk detection."
        },
        item5:{
            label:'Operations & Maintenance Solutions',
            title:'Operations & Maintenance Solutions',
            desc1:'Holistic management system to provide clients with automatic real-time system monitoring and operations & maintenance services',
            desc2:'Comprehensive data storage, data analysis and data governance solutions to ensure secure operations',
            desc3:'Team with extensive experience in operations & maintenance management for global exchanges',
            desc4:'Robust monitoring system and alarm system that sets appropriatethresholds and integrates multiple alarm channels to detect and handle issues in a timely manner'
        }
    },
    section3:{
        title1:"Our ",
        title2:"Achievements",
        subTitle:'Help you build a digital asset exchange safely, fast, and easily',
        text:"Product Service Cases",
        btnText:"Get a demo",
        label1:"Establishment of the company",
        label2:"Top Technology Development Staff",
        label3:"Global Users",
        label4:"Countries and regions"
    },
    section4:{
        title1:"Our ",
        title2:"Team",
        desc:"Blockchain Financial Information Technology Service Provider of the world.Founded in 2020, Headquartered in Dubai. And has professional technology and operation teams in Thailand, Singapore, Korea, Japan, etc.",
        label1:'Establish a digital asset exchange services company in 2020.',
        label2:'Good at blockchain exchange and trading development.',
        label3:'David serves as Vice President of VLink Technology, responsible for brand and marketing. ',
        label4:'CMO and CFO of Standard Chartered Bank and former international advisor of Goldman.'
    },
    section5:{
        title1:"Team ",
        title2:"Genetics",
        desc1:"With a team of over 200 people, the core members of VLink Technology come from IT, finance industry, software engineering and others, and have worked for companies such as Huobi, SOLANA, a16z, Binance, Microsoft, Google, Tencent, Baidu, Amazon and Standard Chartered Bank, etc. Rich experience in the financial, securities, internet, blockchain industry, etc. ",
        desc2:"The proportion of technical R&D staff is over 70%, of which the core R&D staff all have 10+ years of R&D experience."
    },
    section6:{
        title:'Partners',
        subTitle:'We work closely with ecosystem partners to promote industry development.',
        desc1:'Leading global blockchain security brand providing integrated blockchain security products and services',
        desc2:'Providing Everything as a Serviceto build the cloud foundation for an intelligent world',
        desc3:'Web3 security company dedicated to translating state-of-art research into automated security scanning and DevSecOps capabilities',
        desc4:'Global leader in cloudcomputing and artificialintelligence providingreliable and secure cloudcomputing and dataprocessing capabilities',
        desc5:'All-in-one platform foridentity verification, fraudprevention, and KYC/KYB &AML compliance',
        desc6:'Industry-leading Web3cybersecurity solutions provider',
    }
} 