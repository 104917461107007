import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js';
import axios from 'axios'
import mixin from './mixin'
// import '@/untils/rem.js'

import "swiper/dist/css/swiper.css";
import '@/styles/global.scss'
import '@/styles/font.scss'
import VueI18n from 'vue-i18n'
import en from './lang/en/index.js'
import tc from './lang/tc/index.js'
import 'animate.css'
Vue.mixin(mixin)
Vue.use(VueI18n)
Vue.config.productionTip = false

Vue.prototype.$axios = axios;

const i18n = new VueI18n({
  //定义默认语言
  locale: localStorage.getItem('lang')|| 'en',
  messages: {
    'tc': tc,
    'en': en
  }
})
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')


