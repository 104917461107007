export default{
    section1:{
        title:"About us",
        desc:"Blockchain Financial Information Technology Service Provider of the world.Founded in 2020, Headquartered in Dubai. And has professional technology and operation teams in Thailand, Singapore, Korea, Japan, etc.",
        subTitle:"Our service purpose",
        item1:{
            title:"Security",
            desc1:"Global top technical team research and development.",
            desc2:"Professional technical operation and maintenance system to ensure hardware quality.",
            desc3:"Mature financial risk control system that complies with the Cryptocurrency Security Standard (CCSS).",
            desc4:"Perfect capital risk control system, multiple security mechanisms to ensure asset security."
            
        },
        item2:{
            title:"Reliable",
            desc1:"The blockchain data is updated synchronously, and blocks are actively pushed.",
            desc2:"Dedicated server private deployment to support high concurrent transaction access.",
            desc3:"Super anti-DDOS attack system always protects the platform.",
            desc4:"7*24 hours of professional operation and maintenance, contact us anytime, anywhere."
            
        },
        item3:{
            title:"Specialty",
            desc1:"Open API interface to quickly realize digital asset docking.",
            desc2:"Batch processing of digital assets.",
            desc3:'efficient review of orders'
        }
    },
    section2:{
        title:"Our achievements",
        label1:"Partners",
        label2:"Product Service Cases",
        label3:"Top Technology Development Staff",
        label4:"Global Users",
        label5:"Countries and regions",
        label6:"Technology Development Experience"
    },
    section3:{
        title:'Read the latest news of VLINK'
    },
    section5:{
        title:'Customer evaluation',
        text1:'VLINK Technology provided professional guidance and technical support in our upgrade and data update, showed professional and skillful technology in the project process,and enthusiastically helped solve the problems encountered in the project to ensure smooth progress of the project. We would like to express our sincere thanks for this.',
        text2:'Helped us to deploy and implement the system safely and efficiently, provided best practice guidance and daily technical answers. Deployment implementation was successful and no issues were found. They were able to answer and resolve our questions with expertise and a positive attitude at any time.',
    },
    section4:{
        title:"If you have any project or need help. Contact us.",
        form:{
            title:"Send us a message",
            label1:"Name",
            label2:"Contact no.",
            label3:"E-mail",
            label4:"Message",
            btnText:"Submit",
            holder1:"Enter your first name",
            holder2:"Enter your phone number",
            holder3:"Enter your email",
            holder4:"Please leave us a message"
        },
        label1:"Contact us:"
    }
}