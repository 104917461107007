export default{
    section1:{
        title1:"交易所",
        titleH:"錢包",
        title2:"管理系統",
        desc:"我們的系統通過對各種體系和區塊鏈網路資料綜合再處理，為企業和個人客戶提供穩定、方便、快捷的區塊連結入服務。"
    },
    section2:{
        title:"我們的產品生態",
        item1:{
            title:"VLINK企業錢包",
            desc:"為數字資產平臺定制的去中心化管理錢包解決方案。"
        },
        item2:{
            title:"VLINK多簽錢包",
            desc:"支持全幣種多人協同管理，幫助企業安全高效管理大額資金。"
        }
    },
    section3:{
        title:"我們的產品特性",
        item1:{
            title:"功能",
            desc1:"全Token支持",
            desc2:"多Token多地址統一管理",
            desc3:"提現初審+覆核雙模式",
            desc4:"系統待付自動放款",
            desc5:"多地址多資產一鍵歸集",
            desc6:"多員工多許可權一鍵分配",
            desc7:"交易査詢與消息提醒"
        },
        item2:{
            title:"防護",
            desc1:"API介面資訊訪問驗簽、基於HTTPS安全傳輸，拒絕被監聽",
            desc2:"公開金鑰衍生地址，私密金鑰不上傳、不觸網、並通過二次加密託管在用戶端",
            desc3:"多錢包組合，充提分離",
            desc4:"錢包綁定電腦MAC地址，拒絕非認證設備訪問",
            desc5:"冷熱錢包分離，大額資產用冷錢包離線保存"
        },
        item3:{
            title:"保障",
            desc1:"員工操作許可權管理員一鍵設定",
            desc2:"員工操作記錄管理員一鍵査詢",
            desc3:"超額轉出交易需管理員覆核",
            desc4:"员工在非公司指定电脑设备登录优盾账号需管理员同意"
        }
    },
    section4:{
        title:"快速接入VLINK錢包",
        desc:"提供豐富的API介面，比如充值、提現、地址生成等，並支持所有主流數字資產。 公開金鑰衍生科技，私密金鑰永不觸網，封锁駭客入侵，防內鬼盜幣。"
    },
    sectionXix:{
        title:"交易所钱包管理服务内容",
    },
    sectionJ:{
        title:"技术框架"
    },
    section5:{
        title:"風控體系",
        listItem:{
            Sctitle:{
                title:"人員內控",
                desc:"完善的內部系統規範，防止內部人員導致資產損失"
            },
            item1:{
                title:'內部運營體系',
                desc:'研發人員代碼需要二次審計才能申請上線部署，運維人員權限管理，安全小組定期審核線上權限的服務器敏感信息是否滿足部署規範'
            },
            item2:{
                title:'內部風控意識',
                desc:'安全小組會定期組織成員對系統安全進行複盤、法律教育，安全意識 教育。讓人人都敬畏安全。 '
            },
            item3:{
                title:'不同權限管理',
                desc:'運維人員權限定期過期，分項目，模塊進行一對一授權。同時管理後台權限也細分到讀寫，編輯顆粒度'
            }
        },
        listItem2:{
            Sctitle:{
                title:"業務安全",
                desc:"靈活配置幣種的出金限額、審批成員等信息"
            },
            item1:{
                title:'出金策略',
                desc:'靈活配置幣種的出金限額、審批成員等信息'
            },
            item2:{
                title:'安全認證',
                desc:'KYC認證信息、谷歌身份認證器YUBI硬件認證'
            },
            item3:{
                title:'對賬系統',
                desc:'自動對賬系統，實時監控賬戶資金，確保每筆資金清晰明了'
            },
            item4:{
                title:'風控系統',
                desc:'AML、充幣溯源、異地操作認證、冷熱錢包'
            },
            item5:{
                title:'加密機',
                desc:'核心數據存儲、交互均由加密機進行多層加密'
            },
            item6:{
                title:'共管錢包',
                desc:'支持多人對資金管理，出金多人審批'
            },
            item7:{
                title:'數據脫敏',
                desc:'重要信息數據加密顯示'
            },
            item8:{
                title:'審計系統',
                desc:'業務系統行為進行全鏈路監控，確保每步操作均完整有效'
            },
            item9:{
                title:'預警系統',
                desc:'根據報警評價體係對異常行為、數據預先發出警告，防患於未然。系統健康自檢、接口心跳監測、出金時效監控'
            },
            item10:{
                title:'權限系統',
                desc:'員工、系統等各種權限去中心化分配在滿足效率的同時提升安全性'
            },
        },
        listItem3:{
            Sctitle:{
                title:"系統運維安全",
                desc:"完善的內部系統規範，防止內部人員導致資產損失"
            },
            item1:{
                title:'同城雙活架構',
                desc:'服務同城雙活，數據同城災備，同城不丟失數據情況下跨機房級別容災'
            },
            item2:{
                title:'業務子網隔離',
                desc:'不同業務不同子網來物理隔絕系統越權安全，同步解決人員分層問題'
            },
        },
    },
}