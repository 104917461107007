export default {
    section1:{
        title:"流動性解決方案",
        desc:"VLINK提供多種流動性解決方案，包括現貨流動性、衍生品流動性和杠杆ETF流動性。"
    },
    section2:{
        title:"行業領先的流動性",
        item1:{
            desc1:"使用主流交易所的豐厚流動性池",
            desc2:"地理分佈靈活，客戶伺服器遍佈全球",
            desc3:"密切的系統監察，盡量減少故障；我們為客戶提供深入的培訓",
            desc4:"可定制的息差和加成",
            desc5:"支持多幣種交易",
            desc6:"定制的造市解決方案",
        },
    },
    section3:{
        title:"流動性解決方案",
        desc1:"主流幣流動性服務，提供市值管理服務解决主流資產交易流動性、交易深度問題",
        desc2:"包括現貨流動性、杠杆ETF流動性、衍生工具流動性等解決方案",
        desc3:"支持多幣種交易、定制的做市解決方案",
        futures:{
            desc1:"降低市場風險",
            desc2:"穩定性",
            desc3:'低成本高收益方案',
            desc4:"异常情况下的容錯機制",
            desc5:'靈活的异地部署',
            desc6:'全面的業務監控'
        },
    },
    section4:{
        title:"關鍵特色",
        item1:{
            title:"Remarketer",
            title1:'該專有軟件充分定制訂單簿的深度與獨立價格訂單的閾值，同時為市場雙方提供支援。豐富您的交易所流動性，加強訂單簿深度，同時為您的業務提供新的收入來源。',
            desc1:'降低市場風險',
            desc2:'即時最優定價和執行',
            desc3:'自動造市',
            desc4:'重新定義造市訂單'
        },
        item2:{
            title:"流動性聚合",
            title1:'我們從無數個全球交易所聚合流動性，從而形成更好的深度，更低的買賣價差與更佳的買賣價格。',
            desc1:'流動性聚合令您可成為下游經紀、交易所等參與者的一大流動性供應商',
            desc2:'實現價差定制'
        },
    },
    section5:{
        title:"定制化解決方案",
        item1:{
            title:'現貨流動性解決方案',
            title1:'包括代幣保證金流動性和法定貨幣保證金流動性解決方案。代幣保證金流動性指提供相互交換的不同加密幣（USDT、USDC、BUSD、BTC、ETH）及交易對（例如 BTC/USDT、BTC/USDC、BTC/BUSD，以及 ETH/BTC）流動性。法定貨幣保證金流動性指提供與法定貨幣（如美元）互相交換的不同加密幣及交易對（例如 BTC/美元、ETH/美元）的流動性。',
            desc1:'最佳的買賣價',
            desc2:'再造市流動性',
            desc3:'分級後端帳戶管理',
            desc4:'全面的業務監察',
            desc5:'靈活的伺服器託管部署',
            desc6:'定制息差和加成',
            desc7:'綜合統計報告',
            desc8:'完整的訂單歷史',
            desc9:'有效的倉位管理策略',
            desc10:'橋接去中心化到中心化流動性的過渡',
        },
        item2:{
            title:'合約流動性解決方案',
            title1:'永續衍生工具流動性指提供 USDT 保證金永續解決方案的流動性，例如 BTC/USDT、ETH/USDT。',
            desc1:"最佳的買賣價",
            desc2:'再造市流動性',
            desc3:"異況下的故障容忍機制",
            desc4:"綜合統計報告",
            desc5:'分級後端帳戶管理',
            desc6:'全面的業務監察',
            desc7:'綜合統計報告',
            desc8:'完整的訂單歷史',
            desc9:'有效的倉位管理策略',
            desc10:'藉助訂單管理系統的靈活風險管理選擇'
        },
        item3:{
            title:'法幣流動性解決方案',
            title1:'法幣流動性解決方案指基於相關熱門的加密貨幣（例如BTC/USDT、ETH/USDT）ETF提供流動性。 相關加密貨幣解決方案、資金費用、再平衡和其他功能均可定制。',
            desc1:'最佳的買賣價',
            desc2:'靈活的伺服器託管部署',
            desc3:'異況下的故障容忍機制',
            desc4:'綜合統計報告',
            desc5:'分級後端帳戶管理',
            desc6:'風險管理系統',
            desc7:'再造市流動性',
            desc8:'完整的訂單歷史',
            desc9:'再平衡模塊',
            desc10:'可定制的資金費用模塊'
        }
    },
    section6:{
        title:'服務優勢',
        item1:{
            title:"30+ 家交易所深度",
            desc:'VLink聚合全球30多家交易所深度，為客戶帶來足够的流量。 提升交易量的同時，規避被套利虧損風險。'
        },
        item2:{
            title:"超低手續費率",
            desc:'階梯費率，行業最優報價、超低點差，降低對沖成本。'
        },
        item3:{
            title:"100+公鏈，數百幣對",
            desc:'對接100+公鏈，開放數百個幣對。 冷熱錢包和强大的風控體系保證你的資金安全。'
        }
    },
    section7:{
        title:'應用場景',
        item1:{
            title:"適用團隊",
            desc1:'加密貨幣交易所',
            desc2:'加密貨幣經紀商',
            desc3:'機構投資者'
        },
        item2:{
            title:'適用場景',
            desc1:'主流幣對流動性',
            desc2:'合約交易流動性',
            desc3:'現貨交易流動性',
        }
    }
}