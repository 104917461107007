export default {
  section1: {
    title: "Full Stack Digital Asset Exchange Technology Service",
    desc: "We can build exchanges with our professional technical team deployment and customized pages. ",
    desc1:"With our full range of technical support in operations and maintenance to help launch your exchange."
  },
  section2: {
    title: "Our Advantages",
    subTitle: "Customize your exchange with our solutions",
    item1: {
      title: "Security",
      desc: "The front-end adopts firewall anti-attack mechanism, and the back-end adopts hidden separation deployment with whitelist mechanism.",
    },
    item2: {
      title: "Efficiency",
      desc: "High-frequency transaction aggregation engine, based on high-frequency trading, stable support for big data volume, high performance and high concurrency.",
    },
    item3: {
      title: "Fast",
      desc: "The system uses distributed architecture, fast and simple deployment, to achieve the candlestick chart, quotes and data online.",
    },
    item4: {
      title: "Comprehensive",
      desc: "Support for customer customization technology, perfectly fitting the functional needs of different customers.",
    },
  },
  section3: {
    title: "Our digital asset solutions",
    desc1: "Including: spot trading, futures trading, ETF, NFT.",
    desc2: "Including: App, H5, PC.",
    desc3:
      "Provide liquidity services, supporting more than 300 trading pairs.",
    desc4: "Risk control strategies to protect the security of transactions.",
    desc5: "Including: WaaS, source code service and exchange development.",
    desc6: "Utilize VLINK Custody as the underlying wallet, support main chains and corresponding tokens of top three exchanges.",
    desc7: "Include compliance solutions that help address any compliance challenges.",
    desc8: "Leverage distributed matchmaking engine to adapt to sudden traffic influx, second-level disaster recovery."
  },
  section4: {
    title: "Why choose us",
    item1: {
      title: "Security",
      desc: "Bank-grade user data encryption, transaction security, funding management, cold wallet management to ensure the safety of funds.",
    },
    item2: {
      title: "Multiple trading pairs",
      desc: "Built-in multiple functions, customizable and supports multiple trading pairs.",
    },
    item3: {
      title: "High concurrency",
      desc: "High performance, high concurrent professional grade high-speed aggregation engine, based on high frequency concurrent trading design, stable support for large data volume.",
    },
  },
  section5: {
    titleLarge: "Trading Solution",
    title1: "Spot Trading system",
    item1: {
      title: "High performance aggregation",
      desc: "Aggregation system, 50,000 orders per second, comparable to traditional financial trading systems",
    },
    item2: {
      title: "Shared liquidity depth",
      desc: "Top exchange depth, Candlestick Chart, etc., sufficient data, bank-level risk control strategy",
    },
    item3: {
      title: "Risk control security",
      desc: "Independent risk control system, behavior monitoring, withdrawal T+1, strict signature for each capital flow, three-layer wallet system, DDoS defense, traffic cleaning and other 30+ defense mechanisms",
    },
    itemOneLast: {
      title: "Multiple marketing methods",
      desc: "Provide broker, IEO, lock/unlock, mining and other marketing support; flexible configuration of operational functions",
    },
    // 法币
    title2: "Fiat currency trading system",
    item4: {
      title: "Support credit card",
      desc: "Support Visa, MasterCard, ApplePay and other credit card payments, support 126 currencies such as USD, GBP, EUR, etc.,and can buy more than 40 mainstream currencies",
    },
    item5: {
      title: "Compliance Trading",
      desc: "Configuration of fiat bank accounts, withdrawal systems, KYC in accordance with FCA regulatory requirements and GDPR information management principles in line with international standards of AML",
    },
    item6: {
      title: "Configuring the fiat currency",
      desc: "Once opened, exchange users can trade with the platform's acceptors",
    },
    itemTwo: {
      title: "Security system",
      desc: "Security audit of trading funds, KYC authentication of users to prevent swiping, and margin system for coin dealers to ensure capital security",
    },
    // 合约
    title3: " Future trading system",
    item7: {
      title: "Multiple transaction types",
      desc: "Two-way position opening, multiple orders, delivery and perpetual contracts, cross and isolated, forward and reverse contract configuration",
    },
    item8: {
      title: "Financial-level risk control",
      desc: "Platform with zero loss through system risk control, financial level standard real-time monitoring",
    },
    item9: {
      title: "Professional Quantitative Trading",
      desc: "Professional quantitative trading system, the system automatically matches the optimal transaction price, quickly obtains the optimal execution price, and improves the transaction speed",
    },
    itemThree: {
      title: "Margin system",
      desc: "Collateralized margin to open positions, the platform sets the leverage multiplier, the user custom multiplier when opening positions",
    },
  },
  section6: {
    title:'Operation and maintenance solutions',
    item1: {
      title: "Professional operating tools",
      desc: "Solutions to protect the hardware, software, and data of the blockchain system from accidental or malicious damage, changes and disclosure to ensure continuous, reliable and stable operations of the system.",
    },
    item2: {
      title: "Liquidity of VLINK Digital Assets Exchange Solutions",
      desc: "Gain access to deep liquidity pools through our solutions",
    },
  },
  section7: {
    item1: {
      title1: "30+ security technology safeguards",
      title2:'300+ risk control strategies',
      desc1: "Design solution evaluation",
      desc2: "User behavior security assessment",
      desc3: "Transaction security assessment",
      desc4: "Address asset monitoring",
      desc5: "Code security audit",
      desc6: "APP source code reinforcement-MD5 encryption",
      desc7: " VMP source code protection",
      desc8: "File integrity protection",
      desc9: "Penetration Testing",
      desc10: "Anti-tuning reverse analysis",
      desc11: " Program text encryption obfuscation",
      desc12: "Server-side security auditing",
      desc13: "DNS service security monitoring",
      desc14: "Certificate Security Monitoring",
      desc15: "Application security monitoring",
      desc16: "Cold wallet security audit",
      desc17: "Device Access Permission Control",
      desc18: "Business security analysis",
      desc19: "Operating environment monitoring",
      desc20: "Phone root monitoring",
      desc21: "APP integrity monitoring",
      desc22: "Network proxy monitoring",
      desc23: "Network Security Monitoring",
    },
    item2: {
      title: "Server security",
      desc1: "DDOS attack defense, more than 600G broadband anti-DDOS",
      desc2:
        "Tested by multiple security teams, and issued security test reports",
      desc3:
        "Jumpe server, strict audit and permission control, unified management",
    },
    item3: {
      title: "Database security",
      desc1:
        "Security operation and maintenance, effective database account permissions control",
      desc2:
        "Database configuration with multiple encryption, with connection pool decryption use",
      desc3:
        "Multiple signature verification of core data to eliminate data tampering",
      desc4:
        "Data anti-deletion policy, delete and other key operations are fully recorded",
    },
  },
  section8: {
    title: "We help with any compliance challenges",
    descTitle:
      "Compliance requirements vary across industry or country; hence we partner with renowned compliance agencies to provide close to a hundred compliance solutions tailored to specific requirements to meet the highest pass rates globally",
    item1: {
      title: "Compliance Solutions",
      desc: "VLINK provides an all-in-one platform for identity verification, fraud prevention, and KYC/KYB & AML compliance. Its customizable solution verifies users no matter their language or location, helping businesses scale to international markets faster and adhere to global compliance requirements more efficiently. The checks are easily adjustable and range from fully-automated identity verification to agent-assisted verification.",
    },
  },
  section9:{
    title:'Exchange System Architecture'
  },
 
};
