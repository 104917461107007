import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);
let routes = [
    {
		path:'/',
		name:'home',
		component: () => import('@/views/home/components/index.vue'),
		meta:{
			title:'首页'
		},
        redirect: '/products',
        children:[
            {
                path:'/products',
                name:'products',
                component:() => import('@/views/home/index.vue')
            },
            {
                path:'/aboutUs',
                name:'aboutUs',
                component:() => import('@/views/aboutUs/index.vue'),
                meta:{
                    title:'关于我们'
                }
            },
            {
                path:'/mobility',
                name:'mobility',
                component:() => import('@/views/mobility/index.vue'),
                meta:{
                    title:'流动性'
                }
            },
            {
                path:'/Digitaltransactions',
                name:'Digitaltransactions',
                component:() => import('@/views/Digitaltransactions/index.vue'),
                meta:{
                    title:'数字资产'
                }
            },
            {
                path:'/operationalTools',
                name:"operationalTools",
                component:()=>import('@/views/Digitaltransactions/operationalTools.vue')
            },
            {
                path:'/wallet',
                name:'wallet',
                component:() => import('@/views/wallet/index.vue'),
                meta:{
                    title:'钱包'
                }
            },
            {
                path:'/AdditionalServices',
                name:'AdditionalServices',
                component:() => import('@/views/AdditionalServices/index.vue'),
                meta:{
                    title:'增值服务'
                }
            },
            {
                path:'/testAnimation',
                name:'testAnimation',
                component:() => import('@/views/home/testAnimation.vue'),
                meta:{
                    title:'增值服务'
                }
            },
        ]
	},
]
const router = new Router({
	mode:'hash',
	routes
})
export default router;
