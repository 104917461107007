export default {
    section1: {
        title: "安全，快捷，專業幫您搭建數字資產交易所",
        desc: "我們通過專業的科技團隊，定制和部署您的交易所。",
        desc1:'我們提供全方位的技術支援和營運工具，幫助您啟動交易所。'
    },
    section2: {
        title: "產品優勢",
        subTitle: "通過我們的解決方案定制您的交易所",
        item1: {
            title: "安全高效",
            desc: "前端採用防火牆防攻擊機制，後臺採用隱藏式分離部署，具有白名單訪問機制",
        },
        item2: {
            title: "性能卓越",
            desc: "高頻交易撮合引擎，基於高頻交易併發設計，穩定支撐大數據量、高性能、高併發",
        },
        item3: {
            title: "快速部署",
            desc: "系統採用分佈式架構，快速簡單部署，實現k線、行情、深度數據的極速上線測試",
        },
        item4: {
            title: "全方位定制",
            desc: "全面支持客戶定制化科技，完美貼合不同客戶的功能需求",
        },
    },
    section3: {
        title: "我們的數字資產交易所解決方案",
        desc1: "涵蓋市場上交易所的覈心交易模塊，例如現貨交易、期貨交易、ETF、NFT、OTC",
        desc2: "支持三種介面：App、H5、PC",
        desc3: "提供行業領先的流動性服務，支援逾300個交易對",
        desc4: "融入領先的風控策略，務求保障交易的安全性",
        desc5: "兼容三種部署管道，滿足您的需求：WaaS（公共雲）、私有化（租賃）、私有化",
        desc6: "採用 VLINK Custody 作為基礎錢包，支援主流鏈及三大交易所的相應代幣",
        desc7:"包含幫助解決任何合規挑戰的解決方案",
        desc8:"利用分散式匹配引擎，因應流量激增、二級災備的情況進行調整"
    },
    section4: {
        title: "為什麼選擇VLINK",
        item1: {
            title: "安全保障",
            desc: "銀行級用戶資料加密、保障交易安全，錢包多層加密，離線冷存儲，確保資金安全。",
        },
        item2: {
            title: "多幣種交易",
            desc: "內寘多種功能模組，可定制開發，支持多幣種交易。",
        },
        item3: {
            title: "高併發量",
            desc: "高性能、高併發專業級高速撮合引擎，基於高頻併發交易設計，穩定支持大數據量。"
        }
    },
    section5: {
        titleLarge: "覈心交易功能",
        title1: "現貨交易系統",
        item1: {
            title: "高性能撮合引擎",
            desc: "異步撮合機制，每秒50000單，比肩金融交易系統",
        },
        item2: {
            title: "共享流動性深度",
            desc: "上線超一線交易所深度、K線等，充足數據，銀行級別風控策略",
        },
        item3: {
            title: "風控安全保障",
            desc: "獨立風控系統，行為監控，出金T+1、每項資金流水嚴格簽名、三層錢包體系、DDoS防禦、流量清洗等30+項防禦機制。"
        },
        itemOneLast: {
            title: "營銷玩法豐富",
            desc: "提供經紀人、IEO、鎖倉/解鎖、挖礦等營銷支持;運營功能靈活配置"
        },
        // 法币
        title2: "法幣交易系統",
        item4: {
            title: "支持信用卡支付",
            desc: "支持Visa,MasterCard,ApplePay等信用卡支付，支持美元、英鎊、歐元等126種貨幣，可購買40多個主流幣種",
        },
        item5: {
            title: "支持合規出入金",
            desc: "配置法幣銀行賬戶，充提系統，符合FCA監管要求和GDPR信息管理原則的KYC符合國際標準的AML。",
        },
        item6: {
            title: "配置法幣流程",
            desc: "開啟後，交易所的用戶可以與平台的承兌商進行交易"
        },
        itemTwo: {
            title: "系統安全保障",
            desc: "出入金安全審計，用戶KYC認證防刷單，幣商保證金制度，保障資金安全"
        },
        // 法币
        title3: " 合約交易系統",
        item7: {
            title: "多種交易類型",
            desc: "雙向開倉，多種委託，支持交割和永續合約，支持逐倉和全倉模式，正反向合約一鍵配置",
        },
        item8: {
            title: "金融級多重風控",
            desc: "通過制度風控，系統風控，金融級別標準實時監控，真正實時平台0虧損",
        },
        item9: {
            title: "專業合約量化",
            desc: "專業合約量化體系，系統自動匹配最優成交價格，快速獲得最優執行價格，提高成交速度"
        },
        itemThree: {
            title: "保證金制度",
            desc: "抵押保證金開倉，平台設置槓桿倍數，用戶開倉時自定義倍數"
        }
    },
    section6: {
        title:'運維解決方案',
        item1: {
            title: "專業的營運工具",
            desc: "提供保護區塊鏈系統的硬件、軟件及資料避免意外或惡意損害、變更及披露，籍以確保系統的持續、可靠及穩定運作。",
        },
        item2: {
            title: "維鏈數字資產交易流動性解決方案",
            desc: "透過我們的解決方案獲得豐裕的流動性池",
        },
    },
    section7: {
        item1: {
            title1: "30+項安全技術保障",
            title2:'300+風控策略',
            desc1: "設計方案評估",
            desc2: "用戶行為安全評估",
            desc3: "交易安全評估",
            desc4: "地址資產監聽",
            desc5: "代碼安全審計",
            desc6: "APP源碼加固—MD5加密模式",
            desc7: "VMP源碼保護",
            desc8: "文件完整性保護",
            desc9: "滲透測試",
            desc10: "防調式逆向分析",
            desc11: "程序文字加密混淆",
            desc12: "服務端安全審計",
            desc13: "DNS服務安全監測",
            desc14: "證書安全監測 ",
            desc15: "服務端應用安全監測",
            desc16: "冷錢包安全審計",
            desc17: "設備訪問權限控制",
            desc18: "業務執行安全分析",
            desc19: "運行環境監測",
            desc20: "手機root監測",
            desc21: "APP完整性監測",
            desc22: "網絡代理監測",
            desc23: "網絡安全監測",
        },
        item2: {
            title: "服務器安全",
            desc1: "DDOS攻擊防禦,600G以上帶寬抗DDOS",
            desc2: "經多方安全團隊測試，並出具安全測試報告",
            desc3: "堡壘機，嚴格的審計和權限控制，統一管理",
        },
        item3: {
            title: "數據庫安全",
            desc1: "安全運維，有效的數據庫賬戶權限控制",
            desc2: "數據庫配置多重加密，配合連接池解密使用",
            desc3: "核心數據多重簽名校驗，杜絕數據篡改",
            desc4: "數據防刪除策略，刪除等關鍵操作全紀錄",
        },
    },
    section8: {
        title:'我們幫助解決任何合規挑戰',
        descTitle:'合規要求因行業或國家而大相徑庭；因此，我們與最知名的合規機構合作，專門針對具體的要求，提供近百種量身定制的合規解決方案，從而實現全球最高的通過率。',
        item1: {
            title: "合规解决方案",
            desc: "维链科技提供集身份驗證、欺詐預防及 KYC/KYB 和反洗錢合規於一身的平台搭建服务。無論用戶使用何種語言或身在何處，其專屬解決方案皆可驗證用戶的身份，幫助企業快速開拓國際市場和更有效地遵循環球合規要求。客戶可輕鬆地調整檢查，並實現從自動化身份驗證到代理輔助驗證的全覆蓋。",
        },
    },
    section9:{
        title:'交易所系統架構'
    },
    
};
