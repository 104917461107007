export default{
    section1:{
        title:'Professional operating tools',
        desc:"Solutions to protect the hardware, software, and data of the blockchain system from accidental or malicious damage, changes and disclosure to ensure continuous, reliable and stable operations of the system."
    },
    section2:{
        title:"Key Highlights",
        desc:'Solutions to protect the hardware, software, and data of the blockchain system from accidental or malicious damage, changes and disclosure to ensure continuous, reliable and stable operations of the system.',
        desc1:'Over 10 operational tools to facilitate user acquisition and conversion',
        desc2:'Combination of marketing and operational tools with distinguished types and clear goals to facilitate easy adoption',
        desc3:'Marketing tools are used to acquire and retain new users',
        desc4:'Operational tools are used to activate existing users, increase user engagement and retention'
    },
    section3:{
        item1:{
            title:"Reverse Trading",
            desc:'Reverse trading fulfils merchants’ needs to actively hold positions. Merchants only need to register for a copy trading account and make a deposit. After configuration, reverse trading will be done with each transaction conducted by the users under this merchant.',
            desc1:"Active positions can be realized without separate market making",
            desc2:"Allows flexibility to adjust the proportion of trading positions",
            desc3:"Holds partial trading position without the need to prepare hedging funds",
            desc4:"A blacklist can be configured for users with a high winning rate, and the blacklisted users will not be able to open a position when trading",
        },
        item2:{
            title:"Copy Trading - Futures",
            desc:'Copy trading allows the user to automatically follow selected KOL to perform features like trading using selected tokens and liquidation.',
            desc1:"User can observe the KOL's market prediction and trading strategy",
            desc2:"Enhance operating experience while earning profits",
            desc3:"Maintain the right to adjust trading position with the flexibility to stop trading and liquidate at any time",
        },
        item3:{
            title:"Savings",
            desc:'Flexible savings allows deposit and withdrawal at any time, while periodic savings offers high returns. These enable exchanges to provide users token rewards, and customize operations in areas such as user retention, project operations, asset custody analysis etc.',
            desc1:"Periodic (lock-in) savings: Saving funds are locked in and cannot be traded or withdrawn. Users have to wait for the end of the project and the system releases the principal before they can get the invested principal",
            desc2:"Flexible (holding) savings: During the activity period,the user will be able to trade and withdraw assets. Interest will be paid each day only when there is assets balance on the first day's snapshot as well as during each snapshot throughout the activity period.",
        },
        item4:{
            title:"Grid Trading",
            desc:'Grid trading is a strategy that allows users to automatically buy low and sell high within a specific price range through the program. In the volatile digital asset market, grid trading can avoid incorrect trading decisions caused by human factors to a large extent. Grid trading robots will assist users to strictly implement the trading strategy of buying low and selling high set by users.',
            desc1:"After the grid is activated by depositing a single asset (USDT), the system will automatically buy the quantity of each grid above the current price at the beginning, and sell after the price rises. This part of profit will be greater than the profit of grid arbitrage",
            desc2:"When the price fluctuates, each grid arbitrage is the grid profit",
        }
    },
    section4:{
        desc1:'We offer holistic management systems to provide clients with automatic real-time system monitoring and operations & maintenance services',
        desc2:'Comprehensive data storage, data analysis and data governance solutions to ensure secure operations of asset systems',
        desc3:'Team possesses extensive experience in operations & maintenance management for exchanges'
    },
    section5:{
        title:"Competitive Advantage",
        item1:{
            title:'Team Expertise',
            desc1:'A comprehensive team including system operations & maintenance engineer team, database engineer team, security engineer team, operations & maintenance development engineer team, network engineer team'
        },
        item2:{
            title:'Monitoring system',
            desc1:'Robust monitoring system covering cloud monitoring, security monitoring, system infrastructure monitoring, service feasibility monitoring, data processing capability monitoring, network monitoring, log monitoring.'
        },
        item3:{
            title:'Alarm system',
            desc1:'Based on the monitoring system, the system sets appropriate thresholds and integrates multiple alarm channels to detect and handle issues in a timely manner to ensure smooth operations'
        },
        item4:{
            title:'Security Protection',
            desc1:'Comprehensive and multi-level network security protection strategy,Apply security rules to define access rights,Multi-level verification including VPN, public key and OTP/SMS required for login to ensure security; operation logs for comprehensive records to ensure standardization of operations & maintenance'
        },
        item5:{
            title:'Data security',
            desc1:'Real-time, multiple areas hot backup and regular cold backup of data to achieve zero data loss',
            desc2:'Data governance, global data disaster recovery system, database daily monitoring',
            desc3:'Real-time synchronization of data in multiple regions to prevent data loss'
        },
        item6:{
            title:'Operation and maintenance management system',
            desc1:'Developed based on exchange businesses, consolidates operational modules such as asset management, monitoring management, CI and CD etc.'
        },
        item7:{
            title:'Asset management',
            desc1:'Detailed records of the complete asset lifecycle',
            desc2:'Comprehensive infrastructure reinforcement',
            desc3:'Regular upgrading of infrastruture components',
            desc4:'Periodical security check and policy updates'
        },
        item8:{
            title:'Extensive Experience',
            desc1:'Deployed over 300 privatized exchanges and maintain more than 5000 daily active users',
            desc2:'Proficient in exchange full module and business data flow',
            desc3:'Access to various global CDN accelerators'
        },
    },
    section6:{
        title:'Liquidity of Solutions',
        title1:'Gain access to deep liquidity pools through our solutions',
        desc1:'Liquidity pools for 300 SaaS exchanges',
        desc2:'Strong technical expertise and high security',
        desc3:'Multi-strategy, multiple transaction types',
        desc4:'Support 300 + trading pairs',
        desc5:'Comparable to liquidity depth of top tier exchanges',
        desc6:'Increase the depth of client orders through remarketer service while providing new revenue streams',
        desc7:'Server hosting deployment, provides geographic flexibility to clients around the world',
        desc8:'24/7 client support, close monitoring to reduce system downtime and provide clients with in-depth training'
    }
}